import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

const API_CATEGORY_URL = `${environment.apiUrl}/categories`;

@Injectable({
  providedIn: 'root'
})
export class CategoryHttpService {

  constructor(private http: HttpClient) {
  }

  searchCategory(parentCodes: string[]): Observable<any[]> {
    const data = {
      parentCodes: parentCodes
    }
    return this.http.post<any[]>(`${API_CATEGORY_URL}/search`, data);
  }

  findAll(params: any): Observable<any[]> {
    
    return this.http.post<any[]>(`${API_CATEGORY_URL}/findAll`, params);
  }

  getCategoryById(id: any) {
    return this.http.get(`${API_CATEGORY_URL}/${id}`);
  }

  createCategory(payload: any) {
    return this.http.post(`${API_CATEGORY_URL}`, payload);
  }

  updateCategory(payload: any) {
    return this.http.put(`${API_CATEGORY_URL}/${payload.id}`, payload);
  }

  deleteCategory(id: any) {
    return this.http.delete(`${API_CATEGORY_URL}/${id}`);
  }
}
