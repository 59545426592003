import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { AgencyFullModel } from 'src/app/modules/daily/model/agency-full.model';

const API_AGENCY_URL = `${environment.apiUrl}/agencies`;

@Injectable({
  providedIn: 'root'
})
export class AgencyHttpService {

  constructor(private http: HttpClient) {
  }

  getAllAgencies(): Observable<any[]> {
    return this.http.get<any[]>(`${API_AGENCY_URL}`);
  }

  getAgency(id: string): Observable<any> {
    return this.http.get<any>(`${API_AGENCY_URL}/${id}`);
  }

  addAgency(payload: any): Observable<any> {
    return this.http.post<any>(`${API_AGENCY_URL}/add`, payload)
  }

  updateAgency(payload: any): Observable<any> {
    return this.http.put<any>(`${API_AGENCY_URL}/update`, payload)
  }

  deleteAgency(id: string): Observable<any> {
    return this.http.delete<any>(`${API_AGENCY_URL}/${id}`);
  }
}
