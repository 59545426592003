import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserRoleModel } from '../../auth/models/user-role.model';

const API_USER_URL = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  constructor(private http: HttpClient) {}

  getAllUsers(): Observable<any[]> {
    return this.http.get<any[]>(`${API_USER_URL}/get-all-users`);
  }

  findAll(params: any): Observable<any[]> {
    return this.http.post<any[]>(`${API_USER_URL}/findAll`, params);
  }

  getUserById(id: any) {
    return this.http.get(`${API_USER_URL}/${id}`);
  }

  createUser(payload: any) {
    return this.http.post(`${API_USER_URL}/add`, payload);
  }

  updateUser(payload: any) {
    return this.http.put(`${API_USER_URL}/${payload.id}`, payload);
  }

  changeInfo(payload: any) {
    return this.http.post(`${API_USER_URL}/change-info`, payload);
  }

  deleteUser(id: any) {
    return this.http.delete(`${API_USER_URL}/${id}`);
  }

  getAllRoles(): Observable<UserRoleModel[]> {
    return this.http.get<UserRoleModel[]>(`${API_USER_URL}/roles`);
  }
}
