import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthModel} from '../models/auth.model';
import {UserModel} from '../models/user.model';
import {RegistrationModel} from '../models/registration.model';

const API_AUTH_URL = `${environment.apiUrl}/auth`;
const API_USER_URL = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {

  constructor(private http: HttpClient) {
  }

  signup(payload: RegistrationModel): Observable<any> {
    return this.http.post<UserModel>(`${API_AUTH_URL}/signup`, payload);
  }

  login(username: string, password: string): Observable<any> {
    const payload = {
      username, password
    };
    return this.http.post<AuthModel>(`${API_AUTH_URL}/sign-in`, payload);
  }

  forgotPassword(username: string): Observable<any> {
    return this.http.post<boolean>(`${API_USER_URL}/forgot-password`, {username,});
  }

  confirmForgotPassword(token: string, newPassword: string): Observable<any> {
    let params = new HttpParams().set('token', token);
    return this.http.post<boolean>(`${API_USER_URL}/confirm-forgot-password`, {newPassword}, {params});
  }

  getUserByToken(jwt: string): Observable<UserModel> {
    const httpHeaders = new HttpHeaders().set('Authorization', `Bearer ${jwt}`);
    return this.http.get<UserModel>(`${API_AUTH_URL}/me`, {
      headers: httpHeaders,
    });
  }
}
