import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormsModule,
  FormBuilder,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ValidatorFn,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { FormFieldValidationDirective } from '../../../shared/directives/form-field-validation';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import * as dayjs from 'dayjs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { LayoutComponent } from 'src/app/modules/layout/layout.component';
import { first, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserService } from '../../services';
import { ChangeSysUserInfoService } from '../../services/change-sys-user-info.service';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzMessageService } from 'ng-zorro-antd/message';
import { markAsTouched } from '../../utility/form-utility';

export interface OptionFilter {
  id: string;
  label: string;
}

@Component({
  selector: 'change-user-info',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    NzTabsModule,
    NzCardModule,
    NzInputModule,
    NzFormModule,
    NzAutocompleteModule,
    NzDropDownModule,
    NzToolTipModule,
    ReactiveFormsModule,
    FormFieldValidationDirective,
    NzDatePickerModule,
    NzTableModule,
    NzDividerModule,
    NzIconModule,
    FormsModule,
    NzModalModule,
    NzCheckboxModule,
    NzMessageModule,
  ],
  templateUrl: './change-user-info.component.html',
  styleUrls: ['./change-user-info.component.scss'],
})
export class ChangeUserInfoComponent implements OnInit, OnDestroy {
  @Input() data: any;
  agencyFilter: String = '';
  user$: Observable<any>;
  private unsubscribe: Subscription[] = [];
  showPasswordOld: boolean = false;
  showPasswordNew: boolean = false;
  showPasswordNewRetype: boolean = false;
  showFromEmailPass: boolean = false;
  showFromEmailPassRetype: boolean = false;
  isShowAcceptModal = false;

  // @ts-ignore
  changeUserInfo: FormGroup = FormGroup<{
    id: FormControl<string>;
    role: FormControl<string>;
    agencyId: FormControl<string>;
    name: FormControl<string>;
    phone: FormControl<string>;
    email: FormControl<string>;
    username: FormControl<string>;
    passwordOld: FormControl<string>;
    passwordNew: FormControl<string>;
    passwordNewRetype: FormControl<string>;
    configEmail: FormControl<boolean>;
    useSSL: FormControl<boolean>;
    emailServer: FormControl<string>;
    urlEmailServer: FormControl<string>;
    connectionPort: FormControl<string>;
    fromEmailUser: FormControl<string>;
    fromEmailPass: FormControl<string>;
    fromEmailPassRetype: FormControl<string>;
  }>;

  listMailServer: OptionFilter[] = [
    {
      id: 'gmail',
      label: 'Gmail',
    },
    {
      id: 'yahoo',
      label: 'Yahoo',
    },
    {
      id: 'other',
      label: 'Khác',
    },
  ];

  isDisabled: boolean = false;

  constructor(
    private fb: FormBuilder,
    private layout: LayoutComponent,
    private authService: AuthService,
    private modalService: NzModalService,
    private changeSysUserInfoService: ChangeSysUserInfoService,
    private userService: UserService,
    private message: NzMessageService
  ) {
    this.user$ = this.authService.currentUser$;
  }

  private initForm(): void {
    this.changeUserInfo = this.fb.group({
      id: new FormControl(
        { value: '', disabled: false },
        Validators.compose([Validators.maxLength(256)])
      ),
      role: new FormControl(
        { value: '', disabled: false },
        Validators.compose([Validators.maxLength(20)])
      ),
      agencyId: new FormControl(
        { value: '', disabled: false },
        Validators.compose([Validators.maxLength(20)])
      ),
      name: new FormControl(
        { value: '', disabled: false },
        Validators.compose([Validators.required, Validators.maxLength(255)])
      ),
      phone: new FormControl(
        { value: '', disabled: false },
        Validators.compose([Validators.maxLength(20)])
      ),
      email: new FormControl(
        { value: '', disabled: false },
        Validators.compose([Validators.email, Validators.maxLength(255)])
      ),
      username: new FormControl(
        { value: '', disabled: false },
        Validators.compose([Validators.required, Validators.maxLength(255)])
      ),
      passwordOld: new FormControl(
        { value: '', disabled: false },
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(255),
        ])
      ),
      passwordNew: new FormControl(
        { value: '', disabled: false },
        Validators.compose([Validators.minLength(6), Validators.maxLength(255)])
      ),
      passwordNewRetype: new FormControl(
        { value: '', disabled: false },
        Validators.compose([Validators.minLength(6), Validators.maxLength(255)])
      ),
      configEmail: new FormControl({ value: false, disabled: false }),
      useSSL: new FormControl(
        { value: true, disabled: this.isDisabled },
        Validators.compose([Validators.required])
      ),
      emailServer: new FormControl(
        { value: this.listMailServer[0].id, disabled: this.isDisabled },
        Validators.compose([Validators.required, Validators.maxLength(255)])
      ),
      urlEmailServer: new FormControl(
        { value: '', disabled: this.isDisabled },
        Validators.compose([Validators.required, Validators.maxLength(255)])
      ),
      connectionPort: new FormControl(
        { value: '', disabled: this.isDisabled },
        Validators.compose([Validators.required, Validators.maxLength(255)])
      ),
      fromEmailUser: new FormControl(
        { value: '', disabled: this.isDisabled },
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.maxLength(255),
        ])
      ),
      fromEmailPass: new FormControl(
        { value: '', disabled: this.isDisabled },
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(255),
        ])
      ),
      fromEmailPassRetype: new FormControl(
        { value: '', disabled: this.isDisabled },
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(255),
        ])
      ),
    });
  }

  ngOnDestroy(): void {}

  async ngOnInit() {
    this.initForm();
    this.initData();
    this.changeConfigEmail();
  }

  initData(): void {
    this.user$.subscribe((user) => {
      console.log(user);
      this.changeUserInfo.get('id')?.setValue(user.id);
      this.changeUserInfo.get('role')?.setValue(user.roles);
      this.changeUserInfo.get('agencyId')?.setValue(user.agencyId);
      this.changeUserInfo.get('name')?.setValue(user.name);
      this.changeUserInfo.get('username')?.setValue(user.username);
      this.changeUserInfo.get('email')?.setValue(user.email);
      this.changeUserInfo.get('phone')?.setValue(user.phoneNumber);
      this.changeUserInfo.get('username')?.disable();
    });
    // set data default
    this.changeUserInfo.get('urlEmailServer')?.setValue('smtp.gmail.com');
    this.changeUserInfo.get('connectionPort')?.setValue('465');
    this.changeUserInfo.get('fromEmailUser')?.setValue('tultp@efy.com.vn');
    this.changeUserInfo.get('fromEmailPass')?.setValue('123456');
    this.changeUserInfo.get('fromEmailPassRetype')?.setValue('123456');
  }

  toggleShowPassword(name: string): void {
    if (name === 'passwordOld') this.showPasswordOld = !this.showPasswordOld;
    if (name === 'passwordNew') this.showPasswordNew = !this.showPasswordNew;
    if (name === 'passwordNewRetype')
      this.showPasswordNewRetype = !this.showPasswordNewRetype;
    if (name === 'fromEmailPass')
      this.showFromEmailPass = !this.showFromEmailPass;
    if (name === 'fromEmailPassRetype')
      this.showFromEmailPassRetype = !this.showFromEmailPassRetype;
  }

  getFormControlInFormGroup(name: string) {
    return this.changeUserInfo.controls[name];
  }

  checkIsValid(check: string) {
    const control = this.changeUserInfo.get(check);
    return control && control.invalid && (control.dirty || control.touched);
  }

  updateUserInfo(): void {
    markAsTouched(this.changeUserInfo);
    if (!this.changeUserInfo.valid) {
      const invalidFields = [];
      for (const field in this.changeUserInfo.controls) {
        if (this.changeUserInfo.controls[field].invalid) {
          invalidFields.push(field);
        }
      }
      console.log('Invalid fields:', invalidFields);
      return;
    }
    if (
      this.changeUserInfo.get('passwordNewRetype')?.value !==
      this.changeUserInfo.get('passwordNew')?.value
    ) {
      this.errorPas();
      return;
    }
    if (this.changeUserInfo.valid) {
      const updateUserInfoSubscr = this.userService
        .changeInfo({
          id: this.changeUserInfo.get('id')?.value,
          name: this.changeUserInfo.get('name')?.value,
          phoneNumber: this.changeUserInfo.get('phone')?.value,
          email: this.changeUserInfo.get('email')?.value,
          password: this.changeUserInfo.get('passwordOld')?.value,
        })
        .pipe(first())
        .subscribe(
          (res: any) => {
            if (
              res.status_code === '200' &&
              this.changeUserInfo.get('passwordNewRetype')?.value
            ) {
              const changePasswordSubscr = this.changeSysUserInfoService
                .changePassword(
                  this.changeUserInfo.get('passwordOld')?.value,
                  this.changeUserInfo.get('passwordNewRetype')?.value
                )
                .pipe(first())
                .subscribe(
                  (res: any) => {
                    console.log(res);
                    if (res.status_code === '200') {
                      // this.cancelChangeUserInfoComponent();
                      this.message.create(
                        'success',
                        'Cập nhật thông tin thành công'
                      );
                    } else {
                      console.log('Thông báo thất bại');
                      this.message.create(
                        'error',
                        'Cập nhật thông tin thất bại'
                      );
                    }
                  },
                  (error: any) => {
                    if (error.error.status_code === '16') {
                      this.message.create(
                        'error',
                        'Mật khẩu cũ không chính xác'
                      );
                    } else
                      this.message.create(
                        'error',
                        'Cập nhật thông tin thất bại'
                      );
                  }
                );
              this.unsubscribe.push(changePasswordSubscr);
            } else {
              console.log('Cập nhật thông tin thành công');
              this.message.create('success', 'Cập nhật thông tin thành công');
            }
          },
          (error: any) => {
            if (error.error.status_code === '16') {
              this.message.create('error', 'Mật khẩu cũ không chính xác');
            } else this.message.create('error', 'Cập nhật thông tin thất bại');
          }
        );
      this.unsubscribe.push(updateUserInfoSubscr);
    } else {
      console.log('Form is invalid');
    }
  }

  cancelChangeUserInfoComponent(): void {
    this.layout.closeModalChangeUserInfo();
  }

  checkSendMail(): void {}

  errorPas(): void {
    this.isShowAcceptModal = true;
  }

  closeAcceptModal() {
    this.isShowAcceptModal = false;
  }

  changeConfigEmail(): void {
    if (this.changeUserInfo.controls['configEmail'].value) {
      this.isDisabled = false;
      // add validate config email
      this.changeUserInfo.controls['useSSL'].addValidators([
        Validators.required,
      ]);
      this.changeUserInfo.controls['useSSL'].enable();

      this.changeUserInfo.controls['emailServer'].addValidators([
        Validators.required,
      ]);
      this.changeUserInfo.controls['emailServer'].enable();

      this.changeUserInfo.controls['urlEmailServer'].addValidators([
        Validators.required,
      ]);
      this.changeUserInfo.controls['urlEmailServer'].enable();

      this.changeUserInfo
        .get('connectionPort')
        ?.addValidators([Validators.required]);
      this.changeUserInfo.controls['connectionPort'].enable();

      this.changeUserInfo
        .get('fromEmailUser')
        ?.addValidators([Validators.required]);
      this.changeUserInfo.controls['fromEmailUser'].enable();

      this.changeUserInfo
        .get('fromEmailPass')
        ?.addValidators([Validators.required]);
      this.changeUserInfo.controls['fromEmailPass'].enable();

      this.changeUserInfo.controls['fromEmailPassRetype'].addValidators([
        Validators.required,
      ]);
      this.changeUserInfo.controls['fromEmailPassRetype'].enable();
    } else {
      // remove validate config email
      this.isDisabled = true;
      this.changeUserInfo.controls['useSSL'].setErrors(null);
      this.changeUserInfo.controls['useSSL'].clearValidators();
      this.changeUserInfo.controls['useSSL'].disable();

      this.changeUserInfo.controls['emailServer'].setErrors(null);
      this.changeUserInfo.controls['emailServer'].clearValidators();
      this.changeUserInfo.controls['emailServer'].disable();

      this.changeUserInfo.controls['urlEmailServer'].setErrors(null);
      this.changeUserInfo.controls['urlEmailServer'].clearValidators();
      this.changeUserInfo.controls['urlEmailServer'].disable();

      this.changeUserInfo.controls['connectionPort'].setErrors(null);
      this.changeUserInfo.controls['connectionPort'].clearValidators();
      this.changeUserInfo.controls['connectionPort'].disable();

      this.changeUserInfo.controls['fromEmailUser'].setErrors(null);
      this.changeUserInfo.controls['fromEmailUser'].clearValidators();
      this.changeUserInfo.controls['fromEmailUser'].disable();

      this.changeUserInfo.controls['fromEmailPass'].setErrors(null);
      this.changeUserInfo.controls['fromEmailPass'].clearValidators();
      this.changeUserInfo.controls['fromEmailPass'].disable();

      this.changeUserInfo.controls['fromEmailPassRetype'].setErrors(null);
      this.changeUserInfo.controls['fromEmailPassRetype'].clearValidators();
      this.changeUserInfo.controls['fromEmailPassRetype'].disable();
    }
  }
}
