import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

const API_USERS_URL = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root'
})
export class ChangeSysUserInfoHttpService {

  constructor(private http: HttpClient) {
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const payload = {oldPassword, newPassword};
    console.log(payload)
    return this.http.post<any>(`${API_USERS_URL}/change-password`, payload);
  }
}
