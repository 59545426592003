import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './modules/layout/layout.component';
import { AuthGuard } from './auth/services';
import { ForbiddenComponent } from './modules/forbidden/forbidden.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'category',
        loadChildren: () =>
          import('./modules/danhmuc/danhmuc.module').then(
            (m) => m.DanhmucModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'organizational-structure',
        loadChildren: () =>
          import('./modules/cocautochuc/cocautochuc.module').then(
            (m) => m.CocautochucModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'cert',
        loadChildren: () =>
          import('./modules/dichvuchukyso/dichvuchukyso.module').then(
            (m) => m.DichVuChuKySoModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'agency',
        loadChildren: () =>
          import('./modules/daily/daily.module').then((m) => m.DaiLyModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'package',
        loadChildren: () =>
          import('./modules/goicuoc/goicuoc.module').then(
            (m) => m.GoicuocModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
